import PropTypes from "prop-types";
import InputBox from "../UI/InputBox";
import Button from "../UI/Button";
import styles from "../../../scss/components/loginPage/ForgotPasswordFirst.module.scss";

const ForgotPasswordFirst = ({ header, label, onChange, placeholder, value, textBtn, submitForgotPass }) => (
  <div className={styles.container}>
    <h3>{header}</h3>
    <form className={styles.form} onSubmit={submitForgotPass}>
      <InputBox label={label} onChange={onChange} placeholder={placeholder} name="email" value={value} type="email" />
      <Button disabled={value === ""} typeBtn={false} color="buttonLogIn">
        {textBtn}
      </Button>
    </form>
  </div>
);

ForgotPasswordFirst.defaultProps = {
  placeholder: "",
  value: "",
  header: "",
  label: "",
  textBtn: "",
  onChange: () => {},
  submitForgotPass: () => {}
};

ForgotPasswordFirst.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.node,
  onChange: PropTypes.func,
  submitForgotPass: PropTypes.func,
  header: PropTypes.string,
  textBtn: PropTypes.string,
  label: PropTypes.string
};

export default ForgotPasswordFirst;
