import { useContext } from "react";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";

import { removeFromLocalStorage } from "./storageWorks";
import request from "../graphql/serverRequest";
import cookiesWorker from "./cookiesWorker";
import { AppContext, accessToken, refreshToken } from "context/appContext";

import useClearData from "hooks/useClearData";

const Authentication = () => {
  const router = useRouter();
  const { setAuthStatus, setProfile } = useContext(AppContext);
  const clearData = useClearData();

  const { getCookie, saveCookie, removeCookie } = cookiesWorker();

  function updateAuthCookie(authData) {
    if (JSON.parse(getCookie("remember"))) {
      // set expired date cookie
      saveCookie("auth", authData, 30);
    } else {
      // set session cookie
      saveCookie("auth", authData);
    }

    accessToken(JSON.parse(authData).token);
    refreshToken(JSON.parse(authData).refreshToken);
  }

  const [loginUserMutation] = useMutation(request.LOGIN_USER, {
    update(_, { data }) {
      updateAuthCookie(JSON.stringify(data.login.auth));
    }
  });

  const [refreshTokenMutation] = useMutation(request.REFRESH_TOKEN, {
    update(_, { data }) {
      updateAuthCookie(JSON.stringify(data.login.auth));
    }
  });

  const loginUser = (dataUser) => {
    saveCookie("remember", JSON.stringify(dataUser.remember));
    return loginUserMutation({ variables: { input: dataUser } });
  };

  const refreshUserToken = (token) => {
    return refreshTokenMutation({ variables: { input: { token } } });
  };

  const refresh = (data, nameField) => {
    let cookies = getCookie("dataPortal");

    let dataPortal = {};

    if (cookies) {
      cookies = JSON.parse(cookies);
      if (data !== undefined && nameField !== undefined) {
        dataPortal[nameField] = data;
      }
    } else {
      cookies = {};
      if (data !== undefined && nameField !== undefined) {
        dataPortal[nameField] = data;
      }
    }

    dataPortal = { ...dataPortal, ...(cookies || {}) };

    saveCookie("dataPortal", dataPortal);
  };

  const logout = () => {
    removeCookie("auth");
    removeCookie("remember");
    removeCookie("dataPortal");

    clearData();

    // I don't know where this is being set
    removeFromLocalStorage("profile");

    setProfile(null);

    accessToken(null);
    refreshToken(null);

    refresh();
    router.push("/").then(() => {
      window.location.reload();
    });
  };

  return {
    loginUser,
    logout,
    refresh,
    refreshUserToken,
    setAuthStatus
  };
};

export default Authentication;
