import PropTypes from "prop-types";
import { NumericFormat as NumberFormat } from "react-number-format";
import Input from "./Input";
import LabelBox from "../general/LabelBox";
import styles from "../../../scss/components/general/InputBox.module.scss";

const InputBox = ({
  onChange,
  placeholder,
  name,
  value,
  type,
  disable,
  maxlength,
  styleType,
  label,
  textStyle,
  autocomplete,
  id,
  count,
  autofocus,
  onBlur,
  error,
  errorText,
  required,
  format,
  mask,
  asFormattedNumber,
  showCharacterCount
}) => (
  <div className={`${styles.container} ${textStyle && styles[textStyle]}`}>
    <LabelBox
      showCharacterCount={showCharacterCount}
      count={showCharacterCount && count}
      label={`${label || id}${required ? " *" : ""}`}
      maxlength={`${maxlength}`}
      value={value}
    />
    {asFormattedNumber ? (
      <NumberFormat
        customInput={Input}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        type={type}
        disable={disable}
        maxlength={maxlength}
        styleType={styleType}
        autocomplete={autocomplete}
        autofocus={autofocus}
        onBlur={onBlur}
        error={error}
        format={format}
        mask={mask}
        required={required}
      />
    ) : (
      <Input
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        type={type}
        disable={disable}
        maxlength={maxlength}
        styleType={styleType}
        autocomplete={autocomplete}
        autofocus={autofocus}
        requiredInput={required}
        onBlur={onBlur}
        error={error}
      />
    )}
    {(error || (required && !value)) && (
      <span className={styles.error}>
        {`${required && error ? `This field is required.${errorText ? " " : ""}` : ""}${errorText}`}
      </span>
    )}
  </div>
);

InputBox.defaultProps = {
  placeholder: "",
  name: "",
  value: "",
  type: "text",
  disable: "",
  maxlength: 256,
  styleType: "",
  textStyle: "",
  label: "",
  autocomplete: "on",
  id: "",
  errorText: "",
  format: "",
  mask: "",
  count: false,
  autofocus: false,
  error: false,
  required: false,
  asFormattedNumber: false,
  onChange: () => {},
  onBlur: () => {}
};

InputBox.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.node,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disable: PropTypes.string,
  maxlength: PropTypes.number,
  styleType: PropTypes.string,
  textStyle: PropTypes.string,
  label: PropTypes.string,
  autocomplete: PropTypes.string,
  id: PropTypes.string,
  errorText: PropTypes.string,
  format: PropTypes.string,
  mask: PropTypes.string,
  count: PropTypes.bool,
  autofocus: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  asFormattedNumber: PropTypes.bool
};

export default InputBox;
