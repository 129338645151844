import PropTypes from "prop-types";
import Button from "../UI/Button";

const CheckButton = ({ onClick, text, active, name, id }) => (
  <Button color={active ? "checkButtonActive" : "checkButtonDisable"} onClick={onClick} name={name} id={id}>
    <div />
    <span>{text}</span>
  </Button>
);

CheckButton.defaultProps = {
  onClick: () => {},
  text: "",
  active: false,
  name: "",
  id: ""
};

CheckButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  active: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string
};

export default CheckButton;
