import PropTypes from "prop-types";

import styles from "../../../scss/components/general/Input.module.scss";

const Input = ({
  onChange,
  placeholder,
  name,
  value,
  type,
  disable,
  maxlength,
  styleType,
  requiredInput,
  autocomplete,
  id,
  autofocus,
  onBlur,
  error
}) => (
  <input
    className={`${styles.input} ${styleType && styles[styleType]} ${error && styles.redInput}`}
    placeholder={placeholder}
    name={name}
    onChange={onChange}
    value={value}
    type={type}
    disabled={disable}
    maxLength={maxlength}
    required={requiredInput}
    autoComplete={autocomplete}
    id={id}
    autoFocus={autofocus}
    onBlur={onBlur}
  />
);

Input.defaultProps = {
  placeholder: "",
  name: "",
  value: "",
  type: "text",
  disable: "",
  maxlength: 256,
  styleType: "",
  requiredInput: false,
  autofocus: false,
  error: false,
  autocomplete: "on",
  id: "",
  onChange: () => {},
  onBlur: () => {}
};

Input.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.node,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disable: PropTypes.string,
  maxlength: PropTypes.number,
  styleType: PropTypes.string,
  requiredInput: PropTypes.bool,
  autofocus: PropTypes.bool,
  error: PropTypes.bool,
  autocomplete: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func
};

export default Input;
