import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import Wrapper from "../../layouts/Wrapper";
import InputBox from "../UI/InputBox";
import Button from "../UI/Button";
import CheckButton from "../UI/CheckButton";
import LabelLink from "../general/LabelLink";
import styles from "../../../scss/components/loginPage/LoginForm.module.scss";

const LoginForm = ({
  t,
  submitLogin,
  changeFormHandler,
  loginForm,
  openForgotForm,
  statusResetPass,
  error,
  loading
}) => (
  <Wrapper styleWrapper="wrapperForm">
    <h3>{t("logInToYourAccount")}</h3>
    <LabelLink
      href="/create-account"
      label={t("doNotHaveAccount")}
      text={t("createAccount")}
      typeStyle="createAccount"
    />
    <form onSubmit={submitLogin} className={styles.formContainer}>
      {error && <span className={styles.error}>{error}</span>}
      {statusResetPass && <span className={styles.statusResetPass}>{t("youtPassReset")}</span>}
      <InputBox
        label={t("emailAddress")}
        onChange={changeFormHandler}
        placeholder={t("emailAddress")}
        name="email"
        value={loginForm.email}
        type="email"
      />

      <InputBox
        onChange={changeFormHandler}
        placeholder={t("password")}
        name="password"
        value={loginForm.password}
        type="password"
        styleType="password"
        textStyle="password"
        autocomplete="off"
        label="Password"
        id="password-input"
      />
      <div className={styles.btnSubmit}>
        <Button
          color="buttonLogIn"
          typeBtn={false}
          disabled={loading || loginForm.password === "" || loginForm.email === ""}
          id="login-button"
        >
          {t("logIn")}
        </Button>
        <Button color="buttonForgotPass" onClick={openForgotForm} id="forgot-password-button">
          {t("forgotPassword")}
        </Button>
      </div>
      <span className={styles.labelCheckButton}>{t("remeberMe")}</span>
      <CheckButton active={loginForm.remember} id="remember" onClick={changeFormHandler} text={t("remebre30days")} />
    </form>
  </Wrapper>
);

LoginForm.defaultProps = {
  submitLogin: () => {},
  changeFormHandler: () => {},
  openForgotForm: () => {},
  loginForm: {},
  statusResetPass: false,
  error: null
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  submitLogin: PropTypes.func,
  changeFormHandler: PropTypes.func,
  openForgotForm: PropTypes.func,
  loginForm: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    remember: PropTypes.bool
  }),
  statusResetPass: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation("loginPage")(LoginForm);
